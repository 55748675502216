@charset "UTF-8";
//1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/mixin';

//2. Vendors
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';
@import '~sass-mq/_mq.scss';

//3. Base stuff
@import 'base/reset';
@import 'base/color';
@import 'base/utility';
// @import 'base/form';
@import 'base/functions';
@import 'base/text';

//4. components
@import 'components/button';
@import 'components/input';
@import 'components/modal';

//5. Page-specific styles
// @import 'pages/register';
// @import 'pages/landing';
// @import 'pages/policyAndTerm';
@import 'pages/index.scss';
//6. Layout-related sections
@import 'layout/body';

.Basic-modal {
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-close {
    box-shadow: none;
    .ant-modal-close-x {
      font-size: 30px;
      color: #000;
    }
  }

  .quantity-using {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .ingredients {
    white-space: pre-wrap;
  }

  .drug_effect {
    margin: auto;
    display: inline-block;
    span {
      background-color: #f16576 !important;
      padding: 5px 10px;
      color: #fff;
      border-radius: 1px;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
}
