// variables
:root {
  //color
  --color-white: #fff;
  --color-black: #000;
  --color-limed-ash: #767964;
  --color-yuma: #cfc392;
  --color-quill-gray: #d0cfcb;
  --color-silver: #ccc;
  --color-olive: #998800;
  --color-pampas: #f1efe7;
  --color-stiletto: #9a2f2f;
  --color-dusty-gray: #999999;
  --color-chestnut-rose: #ce5848;
  --color-spring-wood: #f5f3e9;
  --color-gurkha: #f16576;
  --color-gurkha-dark: #9c936e;
  --color-firefly: #081c24;
  --color-armadillo: #424037;
  --color-shadow-25: rgba(0, 0, 0, 0.25);
}

//------------------------------------------------------------------------

// ---- font-family
$font-web: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans',
  Meiryo, sans-serif;

// ---- breakpoints
// See https://github.com/sass-mq/sass-mq/ for original full version.
$mq-breakpoints: (
  mobile-small: 321px,
  mobile: 375px,
  mobile-wide: 415px,
  tablet: 768px,
  desktop: 1280px,
);

// ---- font-size
$p: 14px;
$p-sm: 13px;
$p-small: 12px;
$p-medium: 18px;
$p-large: 24px;

// ---- line-height
$lh: 1.5;
$lh-x-small: 1.1;
$lh-small: 1.25;
$lh-large: 1.75;

// ---- font-weight
$w-light: 300;
$w-normal: 400;
$w-semibold: 600;
$w-bold: 700;
$w-heavy: 800;

// ---- base-url
$base-url: '../images' !default;

// color
$color-white: #fff;
$color-black: #000;
$color-limed-ash: #767964;
$color-yuma: #cfc392;
$color-quill-gray: #d0cfcb;
$color-silver: #ccc;
$color-olive: #998800;
$color-pampas: #f1efe7;
$color-stiletto: #9a2f2f;
$color-dusty-gray: #999999;
$color-chestnut-rose: #ce5848;
$color-spring-wood: #f5f3e9;
$color-firefly: #081c24;
$color-armadillo: #424037;
