input {
  font-size: 16px !important;
}

::selection {
  color: var(--color-rangoon-green);
  background: var(--color-stark-white);
}

.aga-input {
  width: 100%;
  padding: 0.8em;
  overflow: hidden;
  border: none;
  background: var(--color-white);
  border-radius: 8px;

  &.ant-input-status-error {
    background-color: $color-white !important;
  }

  &:hover {
    background-color: $color-white !important;
  }
}

.ant-typography-danger {
  font-size: 12px;
}

.ant-select-selector {
  height: 50px !important;

  .ant-select-selection-item {
    line-height: 50px !important;
  }
}

.aga-select {
  width: 100%;
  font-size: 13px;
  overflow: hidden;
  background: $color-white;
  border-radius: 8px;
  padding: 5px;

  .ant-select-selector {
    background-color: $color-white !important;
    border: none !important;
  }
}

.aga-label {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.error-text {
  color: #ff4d4f;
  padding: 0 20px;
  margin-top: -15px;
}

.mt-e-20 {
  margin-top: 15px;
}

.link-mail {
  color: $color-yuma;
  text-decoration: underline;
}
