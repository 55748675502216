.lp-page {
  &__header {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    padding-top: 50px;
    letter-spacing: 0.1em;
  }
}

.medicines {
  .ant-card-body {
    padding: 24px 12px;
  }
}
