//
//Color
//------------------------------------------------------------------------

//------------------------------------| text |------------------------------------

.cl-white {
  color: $color-white !important;
}
.cl-black {
  color: $color-black !important;
}
.cl-limed-ash {
  color: $color-limed-ash !important;
}
.cl-yuma {
  color: $color-yuma !important;
}
.cl-quill-gray {
  color: $color-quill-gray !important;
}
.cl-silver {
  color: $color-silver !important;
}
.cl-pampas {
  color: $color-pampas !important;
}
.cl-stiletto {
  color: $color-stiletto !important;
}
.cl-dusty-gray {
  color: $color-dusty-gray !important;
}
.cl-chestnut-rose {
  color: $color-chestnut-rose !important;
}
.cl-firefly {
  color: $color-firefly !important;
}
.cl-armadillo {
  color: $color-armadillo !important;
}
