.basic-modal {
  .ant-modal-content {
    border-radius: 0.75rem;
  }
  .ant-modal-body {
    padding: 20px 0;
    background: var(--color-spring-wood);
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .modal-content {
    width: 100% !important;
    padding: 20px 40px !important;
  }
}
.modal-menu {
  .ant-drawer-body {
    background: rgb(45, 167, 224, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }
  .menu {
    .menu-item {
      padding: 20px 0;

      &:hover {
        opacity: 0.8;
        background-color: var(--color-gurkha);
      }
    }
    .menu-name {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.13em;
    }
  }
  &__footer {
    padding: 0 36px 19px;
  }
  .logout {
    display: flex;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.13em;
      color: var(--color-white);
      margin-left: 10px;
      margin-bottom: 20px;
    }
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .m---20 {
    padding: 10px 20px 15px;
  }
}
